<template lang="html">
<v-container>
  <v-card icon="mdi-account" >
		<v-card-text>
		<h1 class="display-2 my-2"><strong>Pilih Assignment Pembagian Peran-Hasil Rencana Kinerja Dengan Rekan Kerja</strong></h1>
		<hr>
		<h2 class="my-2">Rencana Kinerja:</h2>
		<label style="font-size:18px">{{dataPegawai.rencana_kinerja}}</label>
		</v-card-text>
		<!-- <v-spacer></v-spacer> -->
    <v-row v-if="dataPegawai.bawahan.length > 0" class="mt-5">
      <v-col v-for="(peg,i) in dataPegawai.bawahan" :key="i" align="center">
		<h3 class="mb-1 green--text text--darken-2" style="height:55px;">
			{{peg.nama_pokja}}
		</h3>
        <base-material-card
          class="v-card-profile"
          :avatar="peg.foto"
          style="width:270px;margin-top:40px"
        >
          <v-card-text class="text-center" style="height:150px">
            <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
              {{peg.peg_nama}}
            </h4>
            <h4 class="font-weight-light grey--text mb-2">
              NIP. {{peg.peg_nip}}
            </h4>
            <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
              {{peg.jabatan_nama}}
            </h4>
            <div class="mt-5">
              <!-- <v-chip small label :class="reviewStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip> -->
            </div>
          </v-card-text>

					<v-card-actions class="d-flex justify-center">
            <div class="d-flex flex-column align-center">
              <v-checkbox v-model="peg.checked" :id="'check-'+peg.peg_nip" @change="checkAssignment(peg, $event)"></v-checkbox>
              <label>&nbsp;</label>
            </div>
            <div class="d-flex flex-column align-center mt-3">
                <v-btn
                color="primary"
                rounded
                class="mr-0"
                :loading="loadingEl == i"
                @click="openDialogAssignment(peg, dataPegawai.rencana_kinerja, i)"
                :disabled="!peg.disabled_assignment"
                >
                <span><v-icon>mdi-plus</v-icon> Assignment <v-badge style="margin-left:10px" color="green" :content="peg.sum_assignment"></v-badge ></span>
                </v-btn>
                <v-btn :loading="loadingElAssignment == i" rounded small color="red darken-4 white--text" class="ma-1" @click="hapusAssignmentSKP(peg, dataPegawai.id, i)">
                  <v-icon small left title="hapus">
                    mdi-delete
                  </v-icon>
                  Hapus Assignment Dan SKP
                </v-btn>
            </div>
					</v-card-actions>

        </base-material-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="5">
        <v-alert
        color="blue-grey"
        dark
        dense
        icon="mdi-account-group"
        prominent
      >
        Tidak ada pegawai
      </v-alert>
      </v-col>
    </v-row>
		<hr class="mt-10 mb-10">
		<div class="ml-3">
			<h3>Tambahkan Assignment Pegawai:</h3>
			<label>(Silahkan tambahkan pegawai jika data rekan/bawahan anda belum muncul)</label>
		</div>
		<v-row class="ml-3">
			<v-col cols="9">
				<v-autocomplete
					:items="listPegawaiSKPD"
					v-model="modelListPegawaiSKPD"
					label="Tambah Assignment Pegawai"
					:item-text="getNipNama"
					multiple
					chips
					return-object
				>
				</v-autocomplete>
			</v-col>
			<v-col cols="3" class="mt-4">
				<v-btn @click="addRow()">Tambah Pegawai</v-btn>
			</v-col>
		</v-row>
		<v-row v-if="dataPegawaiBaru.length > 0" class="mt-5">
      <v-col v-for="(peg,i) in dataPegawaiBaru" :key="i" align="center">
        <base-material-card
          class="v-card-profile"
          :avatar="peg.foto"
          style="width:270px;margin-top:40px"
        >
          <v-card-text class="text-center" style="height:150px">
            <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
              {{peg.peg_nama}}
            </h4>
            <h4 class="font-weight-light grey--text mb-2">
              NIP. {{peg.peg_nip}}
            </h4>
            <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
              {{peg.jabatan_nama}}
            </h4>
            <div class="mt-5">
              <!-- <v-chip small label :class="reviewStatus(peg)" class="ma-1" dark>{{peg.status}}</v-chip> -->
            </div>
          </v-card-text>

					<v-card-actions class="d-flex justify-center">
						<v-checkbox v-model="peg.checked" :id="'check-'+peg.peg_nip" @change="checkAssignment(peg, $event)"></v-checkbox>
            <v-btn
            color="primary"
            rounded
            class="mr-0"
						:loading="loadingEl == i"
            @click="openDialogAssignment(peg, dataPegawai.rencana_kinerja, i)"
            :disabled="!peg.disabled_assignment"
            >
						<span><v-icon>mdi-plus</v-icon> Assignment <v-badge style="margin-left:10px" color="green" :content="peg.sum_assignment"></v-badge ></span>
						</v-btn>
					</v-card-actions>

        </base-material-card>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="5">
        <v-alert
        color="blue-grey"
        dark
        dense
        icon="mdi-account-group"
        prominent
      >
        Tidak ada pegawai
      </v-alert>
      </v-col>
    </v-row>
    <v-card-text><hr></v-card-text>
		<v-card-actions class="d-flex justify-center">
      <v-btn @click="closeEvent()" color="warning">tutup</v-btn>
      <v-btn @click="sendAssignment()" color="primary">Simpan</v-btn>
    </v-card-actions>
  </v-card>
	<v-dialog v-model="dialogFormAssignmentSKPBawahan" persistent>
    <FormAssignmentSKPBawahan :skpMatriksPeranHasil="skpMatriksPeranHasil" :deletedValue="deletedValue" :inputs__="inputs__" :dataPegawaiBawahan="dataPegawaiBawahan" @onClose="(x)=>{dialogFormAssignmentSKPBawahan=x}"/>
  </v-dialog>
</v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import SKPService from '@/services/SKPService'
import FormAssignmentSKPBawahan from './FormAssignmentSKPBawahan.vue'
import axios from 'axios'
import _g from '../../global'

  export default {
		components:{
			FormAssignmentSKPBawahan,
		},
    props:{
			dataPegawai:Array||Object,
			inputs_:Array,
		},
    data() {
        return {
					items: {},
					nip_assignments: [],
					id_check: null,
					dialogFormAssignmentSKPBawahan: false,
					dataPegawaiBawahan: null,
					loadingEl:-1,
					loadingElAssignment:-1,
					skpMatriksPeranHasil: [],
					skpMatriksPeranHasilTw: [],
					inputs__: [{
						deleted: false,
						number: 1,
						nip:'',
						rencana_kinerja_matriks: ''
					}],
					iteration: 1,
					deletedValue: [],
					listPegawaiSKPD: [],
					dataPegawaiBaru: [],
					modelListPegawaiSKPD: [],
					avatarImages:{},
        };
    },
		watch:{
		},
		mounted(){
			this.getDataPegawaiSKPD()
		},
    methods: {
			reviewStatus(peg){
				let status = peg.status ? peg.status.toLowerCase() : ''

				return{
					red:status === 'belum direview',
					blue:status === 'on going',
					green: status === 'sudah direview'
					// green:status == 'belum direview'
				}
			},
			closeEvent(){
				this.$emit('onClose',false)
			},
			checkAssignment(el, $event){
				if(el.disabled_assignment != undefined){
					this.$delete(el, 'disabled_assignment')
				}
				if($event){
					if(!this.nip_assignments.includes(el.peg_nip)){
						this.nip_assignments.push(el.peg_nip)
					}
					el.disabled_assignment = true
				}else{
					var nips = JSON.parse(this.dataPegawai.nip_assignments)
					for (let i = 0; i < nips.length; i++) {
						const element = nips[i];
						this.nip_assignments.push(element)
					}
					if(this.nip_assignments.includes(el.peg_nip)){
						Array.prototype.remove = function() {
								var what, a = arguments, L = a.length, ax;
								while (L && this.length) {
										what = a[--L];
										while ((ax = this.indexOf(what)) !== -1) {
												this.splice(ax, 1);
										}
								}
								return this;
						};
						el.disabled_assignment = false
						this.nip_assignments.remove(el.peg_nip)
					}
				}
				return
			},
			openDialogAssignment(peg, rencana_kinerja, idx){
				this.loadingEl = idx
				this.dataPegawaiBawahan = peg
				this.dataPegawaiBawahan.rencana_kinerja = rencana_kinerja
				// checking if there's existing value
				// array1.concat(array2)
				// this.inputs__.push(this.skpMatriksPeranHasil.concat(this.inputs_))
				if(this.skpMatriksPeranHasil.length > 0){
					this.iteration = 0
					this.inputs__ = []
					for (let i = 0; i < this.skpMatriksPeranHasil.length; i++) {
						const assignment_skp = this.skpMatriksPeranHasil[i];
						for (let j = 0; j < assignment_skp.length; j++) {
							const el = assignment_skp[j];
							if(this.dataPegawaiBawahan.peg_nip == el.nip){
								this.iteration += 1
								this.inputs__.push({
									number: this.iteration,
									nip: this.dataPegawaiBawahan.peg_nip,
									rencana_kinerja_matriks: el.rencana_kinerja_matriks,
									id: el.id,
									deleted: false
								})
							}
						}
					}
				}else{
					this.inputs__ = []
					for (let i = 0; i < this.inputs_.length; i++) {
						const el = this.inputs_[i]
						if(el != undefined){
							if(this.dataPegawaiBawahan.peg_nip == el.nip){
								this.iteration += 1
								this.inputs__.push({
									number: this.iteration,
									nip: this.dataPegawaiBawahan.peg_nip,
									rencana_kinerja_matriks: el.rencana_kinerja_matriks,
									id: el.id,
									deleted: false
								})
							}
						}
					}
				}
				const uniqueElementsBy = (arr, fn) =>
				arr.reduce((acc, v) => {
					if (!acc.some(x => fn(v, x))) acc.push(v);
					return acc;
				}, []);
				this.inputs__ = uniqueElementsBy(this.inputs__,(a, b) => a.id == b.id);
				for (let i = 0; i < this.inputs__.length; i++) {
					const el = this.inputs__[i];
					this.inputs__[i].number = i+1
				}
				this.skpMatriksPeranHasil = this.skpMatriksPeranHasil.filter(function (el) { return el.length != 0 });
				this.dialogFormAssignmentSKPBawahan = true
				this.loadingEl = -1
				this.deletedValue = []
			},
			sendAssignment(){
				var temp_ = this.skpMatriksPeranHasil
				//get all checked assignment
				var a = JSON.parse(this.dataPegawai.nip_assignments)
				if(a != null){
					a.forEach(el => {
						this.nip_assignments.push(el)
					});
				}

				//filtering null value
				if(this.skpMatriksPeranHasil.length > 0){
					if(this.skpMatriksPeranHasil[0].length > 2){
						this.skpMatriksPeranHasil = this.skpMatriksPeranHasil.filter(function (el) { 
							if(el[0] != undefined){
								return el[0].length != 0
							}else{
								return el.length != 0
							}
						});
					}else{
						this.skpMatriksPeranHasil = this.skpMatriksPeranHasil.filter(function (el) { return el.length != 0 });
						// this.skpMatriksPeranHasil[0] = this.skpMatriksPeranHasil[0].filter(function (el) { return el.length != 0 })
					}
				}

				//check if there's null value on checked assignment
				if(this.skpMatriksPeranHasil.length == 0){
					return store.commit('snackbar/setSnack',{message: 'Oops, anda belum menambahkan assignment atau terdapat rekan kerja yang belum mendapatkan assignment, mohon periksa kembali!', color:'error'})
				}

				//join existing value with new value
				var temp = []
				if(this.inputs_.length > 0){
					for (let i = 0; i < this.inputs_.length; i++) {
						const element = this.inputs_[i]
						for (let j = 0; j < this.skpMatriksPeranHasil[0].length; j++) {
							const el = this.skpMatriksPeranHasil[0][j]
							if(element.id == el.id){
								temp.push(el)
							}else{
								temp.push(element)
							}
						}
					}
				}
				temp.forEach(element => {
					this.skpMatriksPeranHasil[0].push(element)
				});


				//filter unique value and convert into stringify
				this.skpMatriksPeranHasil[0] = this.uniqByKeepFirst(this.skpMatriksPeranHasil[0], it => it.id)
				this.skpMatriksPeranHasil[0] = this.skpMatriksPeranHasil[0].filter(function (el) { return el.length != 0 });

				//filter deleted value
				for (let i = 0; i < this.skpMatriksPeranHasil[0].length; i++) {
					const el = this.skpMatriksPeranHasil[0][i];
					for (let j = 0; j < this.deletedValue.length; j++) {
						const el_ = this.deletedValue[j];
						if(el.id == el_.id && el_.deleted == true){
							this.skpMatriksPeranHasil[0].splice(i, 1)
						}
					}
				}
				//filter unchecked pegawai from nip assignments matriks
				var nips = []
				for (let i = 0; i < this.skpMatriksPeranHasil[0].length; i++) {
					const el = this.skpMatriksPeranHasil[0][i];
					if(this.nip_assignments.includes(el.nip)){
						nips.push(el.nip)
					}
				}
				this.nip_assignments = []
				nips.forEach(element => {
					this.nip_assignments.push(element)
				});
				this.nip_assignments = _.uniq(this.nip_assignments)
				this.nip_assignments = this.nip_assignments.filter(function (el) { return el.length != 0 });

				this.items.nip_assignments = JSON.stringify(this.nip_assignments)
				this.items.skp_matriks_peran_hasil = JSON.stringify(this.skpMatriksPeranHasil)
				this.items.id = this.dataPegawai.id //id skp atasan

				//save into database with API
				SKPService.storeAssignmentsSKP(this.items).then(response => {
					let res = response.data
					if (res.success){
						store.commit('snackbar/setSnack',{message: res.message, color:'success'}) 
						this.$emit('onClose',false)
						this.dialogFormAssignmentSKP = false
						this.updateTableTahunan()
						this.inputs_ = []
						this.inputs__ = []
						this.nip_assignments = []
						this.skpMatriksPeranHasil = []
					} else {
						store.commit('snackbar/setSnack',{message: res.message, color:'error'})
					}
        })
			},
			updateTableTahunan(){
				this.loadingTahunan = true
				this.itemsTahunan = {}

				SKPService.getSKP({
					search:'',
					row_per_page:10,
					triwulan:"tahun",
					page:1})
				.then((response)=>{
					// console.log('tahunan',response.data)
					this.itemsTahunan = response.data
					console.log(this.itemsTahunan)
					this.$emit('onTableChange',this.itemsTahunan) //melempar kembali hasil get data ke SKPTahunan.vue dengan method name onTableChange
				}).finally(()=>{
					this.loadingTahunan = false
				})
			},
			uniqByKeepLast(a, key) {
				return [
						...new Map(
								a.map(x => [key(x), x])
						).values()
				]
			},
			uniqByKeepFirst(a, key) {
				let seen = new Set();
				return a.filter(item => {
						let k = key(item);
						return k == undefined ? seen.add(k) : seen.has(k) ? false : seen.add(k)
						// return seen.has(k) ? false : seen.add(k);
				});
			},
			getImg(nip, peg_foto){
        let avatarImgUrl = "https://simpeg.bandung.go.id/uploads/" + peg_foto
				this.avatarImages[nip] = avatarImgUrl
				return avatarImgUrl
			},
			getDataPegawaiSKPD(){
				let kode_skpd = this.$store.getters["user/data"].data_asn.kolok
				let current = store.state.user.current
				let user = store.state.user
				if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
					current.username = _g.overrideWithPLT(user.current.role)
				}else{
					current.username = user.current.username
				}

				var base_url_api = _g.getBaseURLAPIERK(current.year)
				const url_get_data = base_url_api + 'v1/get-pegawai-skpd-req-post'
				axios.post(url_get_data, {
					nip: current.username, kode_skpd: kode_skpd
				}).then(response => {
					response.data.data.forEach(element => {
						element.foto = this.getImg(element.nip, element.peg_foto)
					});
					this.listPegawaiSKPD = response.data.data
				}).catch(err => {
					var error = err.message ? err.message : err.response.data.message
					this.loadingEl = -1
					store.commit('snackbar/setSnack',{message: error, color:'error'})
				})
				// SKPService.getPegawaiSKPD(kode_skpd).then(response=>{
				// 	response.data.data.forEach(element => {
				// 		element.foto = this.getImg(element.nip, element.peg_foto)
				// 	});
				// 	this.listPegawaiSKPD = response.data.data
				// })
			},
			getNipNama(item) {
				return `${item.peg_nip} - ${item.peg_nama}`;
				// return `${item.nip} - ${item.nama}`;
			},
			addRow(){
				this.no = this.dataPegawaiBaru.length
				this.dataPegawaiBaru = []
				this.modelListPegawaiSKPD.forEach(element => {
					this.no += 1
					var my_object = {
						foto: element.foto,
						peg_nama: element.peg_nama,
						peg_nip: element.peg_nip,
						// peg_nama: element.nama,
						// peg_nip: element.nip,
						jabatan_nama: element.jabatan,
						no:this.no,
						checked: false,
						disabled_assignment: false
					};
					this.dataPegawaiBaru.push(my_object)
				});
			},
			removeItem(item) {
				this.no -= 1
				for (let i = 0; i < this.dataPegawaiBaru.length; i++) {
					const el = this.dataPegawaiBaru[i];
					if(el.no == item.no){
						this.dataPegawaiBaru.splice(i, 1)
					}
				}
				// re-indexing
				for (let i = 0; i < this.dataPegawaiBaru.length; i++) {
					this.dataPegawaiBaru[i].no = i+1
				}
				console.log(this.dataPegawaiBaru)
			},
      hapusAssignmentSKP(peg, id, idx){
        if (confirm("Apakah anda yakin ingin menghapus assignment SKP beserta SKP bawahan anda?")){
          this.loadingElAssignment = idx
          var url = "v1/hapus-assignment-skp-tahunan"
          var current = this.$store.getters["user/current"]
          var base_url_api = _g.getBaseURLAPIERK(current.year)
          const url_ = base_url_api + url
          axios.post(url_, {nip: current.username, nip_bawahan: peg.peg_nip, api:1, id:id}).then(response => {
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: res.message, color:'success'})
            }else{
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          }).finally(()=>{
            this.loadingElAssignment = -1
            this.$emit('onClose',false)
            this.updateTableTahunan()
          }).catch(err => {
            this.loadingElAssignment = -1
            var error = err.response.data.message
            this.$emit('onClose',false)
            store.commit('snackbar/setSnack',{message: error, color:'error'})
            this.updateTableTahunan()
          })
        }
      }
    },
  }
</script>
