<template lang="html">
<v-container>
  <v-card icon="mdi-account" >
		<v-card-text>
		<h1 class="display-2 my-2">Form Tambah Assignment Pembagian Peran-Hasil</h1>
		<hr>
		<h3 class="my-2">Rencana Kinerja Anda:</h3>
		<label>{{dataPegawaiBawahan.rencana_kinerja}}</label>
		</v-card-text>
		<!-- <v-spacer></v-spacer> -->
    <v-row class="mt-5">
      <v-col align="center" cols="4">
		<h3 class="mb-1 green--text text--darken-2" style="height:55px;">
			{{dataPegawaiBawahan.nama_pokja}}
		</h3>
        <base-material-card
          class="v-card-profile"
          :avatar="dataPegawaiBawahan.foto"
          style="width:270px;margin-top:40px"
        >
          <v-card-text class="text-center" style="height:150px">
            <h4 class="display-1 mb-1 orange--text text--darken-2" style="height:55px;">
              {{dataPegawaiBawahan.peg_nama}}
            </h4>
            <h4 class="font-weight-light grey--text mb-2">
              NIP. {{dataPegawaiBawahan.peg_nip}}
            </h4>
            <h4 class="font-weight-light green--text" style="font-size:12px; height:30px">
              {{dataPegawaiBawahan.jabatan_nama}}
            </h4>
            <div class="mt-5">
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>

			<template>
				<div v-if="inputs__.length > 0" style="width:50%">
					<div v-for="(value, key) in inputs__" :key="key.number" align="center">
						<v-row dense class="mt-5 col-md-12" v-if="value.deleted != true">
							<v-textarea v-model="value.rencana_kinerja_matriks" :label="'Rencana Kinerja Matriks Peran-Hasil Ke-'+(value.number != undefined ? value.number : value.number)" outlined auto-grow></v-textarea>
							<v-col>
								<v-btn @click="add(value)">Tambah</v-btn>
								<v-btn v-if="key > 0" color="error" @click="remove(value)">Hapus</v-btn>
							</v-col>
						</v-row>
					</div>
				</div>
				<div v-else style="width:50%">
					<div v-for="(value, key) in inputs" :key="key.number" align="center">
						<v-row dense class="mt-5 col-md-12" v-if="value.deleted != true">
							<v-textarea v-model="value.rencana_kinerja_matriks" :label="'Rencana Kinerja Matriks Peran-Hasil Ke-'+(key+1)" outlined auto-grow></v-textarea>
							<v-col>
								<v-btn @click="add(value)">Tambah</v-btn>
								<v-btn v-if="key > 0" color="error" @click="remove(value)">Hapus</v-btn>
							</v-col>
						</v-row>
					</div>
				</div>
			</template>

    </v-row>
		<v-card-text><hr></v-card-text>
		<v-card-actions class="d-flex justify-center">
      <v-btn @click="closeEvent()" color="warning">tutup</v-btn>
			<v-btn @click="addAssignments()" color="primary">Tambahkan</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'

  export default {
    props:{
			dataPegawaiBawahan:Object,
			inputs__:Array,
			skpMatriksPeranHasil:Array,
			deletedValue:Array
		},
    data() {
        return {
					items: [],
					nip_assignments: [],
					id_check: null,
					dialogFormAssignmentSKPBawahan: false,
					inputs: [{
            deleted: false,
						number: 1,
						nip:'',
						rencana_kinerja_matriks: ''
					}],
					max_inputs: 3,
					iteration: 1,
					skpMatriksPeranHasil_:[],
        };
    },
    methods: {
			closeEvent(){
				this.$emit('onClose',false)
			},
			add () {
				// this.iteration = this.inputs.length
				this.iteration = this.inputs__.length
				this.iteration += 1
				if(this.inputs__.length > 0){
					this.inputs__.push({
            deleted: false,
						number: this.iteration,
						nip: this.dataPegawaiBawahan.peg_nip,
						rencana_kinerja_matriks: '',
					})
					console.log(this.inputs__)
				}else{
					this.inputs.push({
            deleted: false,
						number: this.iteration,
						nip: this.dataPegawaiBawahan.peg_nip,
						rencana_kinerja_matriks: '',
					})
					console.log(this.inputs)
				}
			},
			remove (index) {
				this.iteration -= 1
				if(this.inputs__.length > 0){
					this.inputs__.splice(index.number-1, 1)
					// re-indexing
					for (let i = 0; i < this.inputs__.length; i++) {
						const el = this.inputs__[i];
						this.inputs__[i].number = i+1
					}
					// this.inputs__.push({
					// 	number: index.number,
					// 	nip: index.nip,
					// 	rencana_kinerja_matriks: index.rencana_kinerja_matriks,
					// 	id: index.id,
					// 	deleted: true
					// })
					this.deletedValue.push({
						number: index.number,
						nip: index.nip,
						rencana_kinerja_matriks: index.rencana_kinerja_matriks,
						id: index.id,
						deleted: true
					})
				}else{
					this.inputs.splice(index.number-1, 1)
					// re-indexing
					for (let i = 0; i < this.inputs.length; i++) {
						const el = this.inputs[i];
						this.inputs[i].number = i+1
					}
				}
				console.log(this.inputs__)
			},
			addAssignments() {
				var values = [];
				// var i = 0
				var nip = this.dataPegawaiBawahan.peg_nip
				var check = null

				// remove existing value
				for(var i = 0; i < this.skpMatriksPeranHasil_.length; i++){
					if(this.skpMatriksPeranHasil_[i] != undefined){
						var temp = this.skpMatriksPeranHasil_[i]
						for (let j = 0; j < temp.length; j++) {
							if(temp[j].nip == nip){
								this.skpMatriksPeranHasil_[i].splice(j,1, []);
							}
						}
					}
				}

				// filter empty array
				if(this.skpMatriksPeranHasil_.length > 0){
					if(this.skpMatriksPeranHasil_[0] != undefined){
						this.skpMatriksPeranHasil_[0] = this.skpMatriksPeranHasil_[0].filter(function (el) { return el.length != 0 })
					}
				}

				if(this.inputs__.length > 0){
					for (let i = 0; i < this.inputs__.length; i++) {
						const el = this.inputs__[i];
						if(el.rencana_kinerja_matriks == ''){
							// alert('Isi dahulu rencana kinerja matriks peran-hasil bawahan anda!');
							check = false
							break
						}else{
							check = true
							if(!el.deleted){
								values.push({
									"number": i+1,
									"rencana_kinerja_matriks": el.rencana_kinerja_matriks,
									"nip": el.nip == '' ? nip : el.nip,
									"id": el.id == '' ? nip : el.id,
									"deleted": el.deleted
								})
							}
						}
					}
				}else{
					// insert new array value
					if(this.inputs.length > 0){
						for (let i = 0; i < this.inputs.length; i++) {
							const el = this.inputs[i];
							if(el.rencana_kinerja_matriks == ''){
								// alert('Isi dahulu rencana kinerja matriks peran-hasil bawahan anda!');
								check = false
								break
							}else{
								check = true
								if(!el.deleted){
									values.push({
										"number": i+1,
										"rencana_kinerja_matriks": el.rencana_kinerja_matriks,
										"nip": el.nip == '' ? nip : el.nip,
										"id": el.id == '' ? nip : el.id,
										"deleted": el.deleted
									})
								}
							}
						}
					}
				}
				if(!check){
					alert('Isi dahulu rencana kinerja matriks peran-hasil bawahan anda!')
					return false
				}

				// if(this.skpMatriksPeranHasil.length > 0){
				// 	if(this.skpMatriksPeranHasil[0].length > 0)
				// 	{
				// 		this.skpMatriksPeranHasil = []
				// 	}
				// }

				// filter empty array
				if(this.skpMatriksPeranHasil[0] != undefined){
					values.forEach(element => {
						this.skpMatriksPeranHasil[0].push(element)
					});
				}else{
					this.skpMatriksPeranHasil.push(values)
				}
				this.inputs = [{
					number: 1,
					nip:'',
					rencana_kinerja_matriks: ''
				}]
        this.skpMatriksPeranHasil_.push(values)
				if(this.skpMatriksPeranHasil_.length > 2){
					this.skpMatriksPeranHasil_ = this.skpMatriksPeranHasil_.filter(function (el) { 
						if(el[0] != undefined){
							return el[0].length != 0
						}else{
							return el.length != 0
						}
					});
				}else{
					this.skpMatriksPeranHasil_ = this.skpMatriksPeranHasil_.filter(function (el) { return el.length != 0 });
				}

				// count inserted value
				var count = 0
				for (let i = 0; i < this.skpMatriksPeranHasil_.length; i++) {
					const assignment_skp = this.skpMatriksPeranHasil_[i];
					for (let j = 0; j < assignment_skp.length; j++) {
						const el = assignment_skp[j];
						if(nip == el.nip){
							count += 1
						}
					}
				}
				// append counted value
				this.dataPegawaiBawahan.sum_assignment = count
				this.$emit('onClose',false)
			}
    },
  }
</script>
